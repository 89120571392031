<template>
    <div class="preview_area" :class="{ active: modelValue }" v-if="modelValue">
        <button type="button" class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button>
        <div class="analytics_container">
            <h3 class="plan_title">
                {{ plan.name }}
                <ul>
                    <li>{{ plan.workouts_count }} <span>Workouts</span></li>
                    <li>{{ plan.blocks_count }} <span>Blocks</span></li>
                    <li>{{ plan.weeks.length }} <span>Weeks</span></li>
                </ul>
            </h3>
            <div class="analytics">
                <div class="analytics_card" v-if="planAnalyticsLoader"><quote-loader :show-quote="false" /></div>
                <div class="analytics_card" v-else>
                    <h4>Assigned <span class="stat">{{ planAnalytics.assign_count }}</span></h4>
                    <ul class="analytic_info column pt-2">
                        <li>
                            <h5>Completed: <span class="stat">{{ planAnalytics.completed }}</span></h5>
                            <div class="score_circle">
                                <div class="inner_circle">
                                    <div class="score">{{ planAnalytics.completion_rate }}%</div>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                    <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px"
                                        stroke="#f2a31d" :stroke-dashoffset="115 - (planAnalytics.completion_rate * 1.15)"
                                        stroke-dasharray="115" />
                                </svg>
                            </div>
                        </li>
                        <li>
                            <h5>In Progress: <span class="stat">{{ planAnalytics.inprogress }}</span></h5>
                            <div class="score_circle">
                                <div class="inner_circle">
                                    <div class="score">{{ planAnalytics.inprogress_rate }}%</div>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                    <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px"
                                        stroke="#1df2ce" :stroke-dashoffset="115 - (planAnalytics.inprogress_rate * 1.15)"
                                        stroke-dasharray="115" />
                                </svg>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="analytics_card" v-if="planAnalyticsLoader"><quote-loader :show-quote="false" /></div>
                <div class="analytics_card" v-else>
                    <h4>Ratings</h4>
                    <div class="reviews">
                        <ul>
                            <li>Average Difficulty
                                <star-rating 
                                    :increment="0.01"
                                    :max-rating="5"
                                    :rating="planAnalytics.average_difficulty"
                                    active-color="#2f7eed"
                                    active-border-color="#2f7eed"
                                    :border-width="1"
                                    border-color="#c5c5c5"
                                    :show-rating="false"
                                    :star-size="8"
                                    :read-only="true"
                                    :rounded-corners="true"
                                    inactive-color="#c5c5c5"
                                ></star-rating>
                            </li>
                            <li>Average Enjoyment
                                <star-rating 
                                    :increment="0.01"
                                    :max-rating="5"
                                    :rating="planAnalytics.average_enjoyment"
                                    active-color="#2f7eed"
                                    active-border-color="#2f7eed"
                                    :border-width="1"
                                    border-color="#c5c5c5"
                                    :show-rating="false"
                                    :star-size="8"
                                    :read-only="true"
                                    :rounded-corners="true"
                                    inactive-color="#c5c5c5"
                                ></star-rating>
                            </li>
                            <li>Average Energy
                                <star-rating 
                                    :increment="0.01"
                                    :max-rating="5"
                                    :rating="planAnalytics.average_energy"
                                    active-color="#2f7eed"
                                    active-border-color="#2f7eed"
                                    :border-width="1"
                                    border-color="#c5c5c5"
                                    :show-rating="false"
                                    :star-size="8"
                                    :read-only="true"
                                    :rounded-corners="true"
                                    inactive-color="#c5c5c5"
                                ></star-rating>
                            </li>
                            <li>Average Mood
                                <star-rating 
                                    :increment="0.01"
                                    :max-rating="5"
                                    :rating="planAnalytics.average_mood"
                                    active-color="#2f7eed"
                                    active-border-color="#2f7eed"
                                    :border-width="1"
                                    border-color="#c5c5c5"
                                    :show-rating="false"
                                    :star-size="8"
                                    :read-only="true"
                                    :rounded-corners="true"
                                    inactive-color="#c5c5c5"
                                ></star-rating>
                            </li>
                        </ul>
                    </div>
                    <ul class="analytic_info">
                        <li>
                            <label>Highest Rated Workout:
                                <star-rating 
                                    :increment="0.01"
                                    :max-rating="5"
                                    :rating="planAnalytics.highest_rating.rating"
                                    active-color="#2f7eed"
                                    active-border-color="#2f7eed"
                                    :border-width="1"
                                    border-color="#c5c5c5"
                                    :show-rating="false"
                                    :star-size="8"
                                    :read-only="true"
                                    :rounded-corners="true"
                                    inactive-color="#c5c5c5"
                                ></star-rating>
                                <span class="workout">{{  planAnalytics.highest_rating.workout  }}</span>
                            </label>
                        </li>
                        <li>
                            <label>Lowest Rated Workout:
                                <star-rating 
                                    :increment="0.01"
                                    :max-rating="5"
                                    :rating="planAnalytics.lowest_rating.rating"
                                    active-color="#2f7eed"
                                    active-border-color="#2f7eed"
                                    :border-width="1"
                                    border-color="#c5c5c5"
                                    :show-rating="false"
                                    :star-size="8"
                                    :read-only="true"
                                    :rounded-corners="true"
                                    inactive-color="#c5c5c5"
                                ></star-rating>
                                <span class="workout">{{  planAnalytics.lowest_rating.workout  }}</span>
                            </label>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="section_title">
                Global Stats
                <div class="filter" @click="leaderboardFilterDropdown = !leaderboardFilterDropdown"
                    v-click-outside="closeLeaderboardFilterDropdown">
                    Filter by {{ globalStatsSelectedFilters }} <i class="fas fa-angle-down"></i>
                    <div class="dropdown_wpr" :class="{ 'active': leaderboardFilterDropdown }">
                        <ul>
                            <li v-for="(filter, f) in globalStatsFilters" :key="f"
                                @click="globalStatsSelectedFilters = filter;">Filter by {{ filter }}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="leaderboard_wpr">
                <div class="leaderboard">
                    <div class="board_ttl">
                        Workout Streaks
                        <i class="fas fa-trophy ml-auto"></i>
                    </div>
                    <ul class="analytics_card" v-if="planAnalyticsLoader"><quote-loader :show-quote="false" /></ul>
                    <ul v-else>
                        <li v-for="(streak, p) in planAnalytics.workout_streaks_score" :key="p">
                            <span class="board_pos">{{ p + 1 }}</span>
                            <img :src="streak.photo" alt="">
                            <h5>{{ streak.name }}</h5>
                            <div class="streaks">{{ streak.score }}</div>
                        </li>
                        <p class="no-stats" v-if="planAnalytics.workout_streaks_score.length == 0">No workout streaks found.</p>
                    </ul>
                </div>
                <div class="leaderboard">
                    <div class="board_ttl">
                        Plan Completion Status
                        <i class="fas fa-trophy ml-auto"></i>
                    </div>
                    <ul class="analytics_card" v-if="planAnalyticsLoader"><quote-loader :show-quote="false" /></ul>
                    <ul v-else>
                        <li v-for="(completion, c) in planAnalytics.plan_completion_score" :key="c">
                            <span class="board_pos">{{ c + 1 }}</span>
                            <img :src="completion.photo" alt="">
                            <h5>{{ completion.name }}</h5>
                            <div class="score_circle">
                                <div class="inner_circle">
                                    <div class="score">{{ completion.score }}<span>%</span></div>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" height="24">
                                    <circle cx="12" cy="12" r="11.5" stroke-linecap="round" fill="none" stroke-width="1px"
                                        stroke="#2f7eed" :stroke-dasharray="70"
                                        :stroke-dashoffset="70 - (completion.score * 0.7)" />
                                </svg>
                            </div>
                        </li>
                        <p class="no-stats" v-if="planAnalytics.plan_completion_score.length == 0">No plan completion status found.</p>
                    </ul>
                </div>
                <div class="leaderboard">
                    <div class="board_ttl">Overall Plan Rating
                        <i class="fas fa-trophy ml-auto"></i>
                    </div>
                    <ul class="analytics_card" v-if="planAnalyticsLoader"><quote-loader :show-quote="false" /></ul>
                    <ul v-else>
                        <li v-for="(rating, r) in planAnalytics.overall_rating_score" :key="r">
                            <span class="board_pos">{{ r + 1 }}</span>
                            <img :src="rating.photo" alt="">
                            <h5>{{ rating.name }}</h5>
                            <star-rating 
                                :increment="0.01"
                                :max-rating="5"
                                :rating="rating.score"
                                active-color="#2f7eed"
                                active-border-color="#2f7eed"
                                :border-width="1"
                                border-color="#c5c5c5"
                                :show-rating="false"
                                :star-size="8"
                                :read-only="true"
                                :rounded-corners="true"
                                inactive-color="#c5c5c5"
                            ></star-rating>
                        </li>
                        <p class="no-stats" v-if="planAnalytics.overall_rating_score.length == 0">No overall plan rating found.</p>
                    </ul>
                </div>
            </div>
            <div class="section_title mt-2">Clients</div>
            <div class="tab_wpr">
                <div class="result_wpr new">
                    <div class="actions">
                        <ul class="left">
                            <li class="optionDrops contacts-tabs" @click="filterDropdown = !filterDropdown"
                                v-click-outside="closeFilterDropdown">
                                {{ params.filter }} <i class="fas fa-angle-down"></i>
                                <div class="dropdown_wpr" :class="{ 'active': filterDropdown }">
                                    <ul>
                                        <li v-for="(filter, f) in filters" :key="f" @click="params.filter = filter;">
                                            {{ filter }}</li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                        <ul class="right">
                            <li class="optionDrops sort_list ml-auto" @click="togglePerPageFilter()"
                                v-click-outside="closePerPageDropdown">
                                Show {{ params.per_page }} <i class="fas fa-angle-down"></i>
                                <page-filter v-model="params.per_page" :type="2" :is-dropdown="true"
                                    ref="workout-user-per-page-filter" />
                            </li>
                        </ul>
                    </div>
                    <div class="scroll_table">
                        <table class="standard show_header">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Status</th>
                                    <th>Day's Completed</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(contact, c) in contacts.data" :key="c">
                                    <td>{{ moment(contact.created_at).format('ll') }}</td>
                                    <td>{{ contact.contact ? contact.contact.name : '-' }}</td>
                                    <td>{{ contact.contact ? contact.contact.email : '-' }}</td>
                                    <td><span class="tag">{{ contact.status }}</span></td>
                                    <td>{{ contact.responses_count }}</td>
                                    <td>
                                        <button type="button" @click="toggleContactActivity(contact)"><i class="fas fa-search-plus"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="table_footer">
                    <ul>
                        <li>{{ contacts.from ? contacts.from : 0 }} - {{ contacts.to ? contacts.to : 0 }} of <span>{{ contacts.total ? contacts.total : 0 }}</span></li>
                    </ul>
                </div>
            </div>
            <div class="model_pagination pb-4 mt-4">
                <div class="pagination" v-show="contacts.total">
                    <pagination v-model="params.page" :range-size="0" :pages="contacts.last_page" @update:modelValue="handlePagination" />
                </div>
            </div>
        </div>
    </div>
    <plan-activity v-model="viewActivity" :assign-id="selectedContact.id" :contact-id="selectedContact.contact_id" :plan-id="selectedContact.workout_plan_id" />
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapState, mapActions } from 'vuex'

const PageFilter = defineAsyncComponent(() => import('@/components/PageFilter'))
const PlanActivity = defineAsyncComponent(() => import('@/pages/workout/components/plan/PlanActivity'))

import moment from 'moment-timezone'
import StarRating from 'vue-star-rating'

export default {
    name: 'Workout Plan Analytics',

    data() {
        return {
            params: {
                id: 0,
                page: 1,
                per_page: 5,
                search: '',
                filter: 'All',
                status: 'all',
                analytic: true,
                assetType: 'plan',
            },
            filterDropdown: false,
            filters: ['All', 'Active', 'Completed', 'Restricted'],
            selectedContact: {},
            leaderboardFilterDropdown: false,
            globalStatsSelectedFilters: 'Active',
            globalStatsFilters: ['Active', 'Completed', 'Restricted'],
            viewActivity: false,
            isTyping: false,
            moment,
        }
    },
    components: {
        PageFilter,
        StarRating,
        PlanActivity,
    },

    props: {
        modelValue: Boolean,
        plan: Object,
    },

    emit: ['update:modelValue'],

    watch: {
        modelValue (val) {
            const vm = this;

            if (val) {
                vm.params.id = vm.plan.id;
                vm.params.page = 1;

                vm.getPlanAnalytics({ plan: vm.plan.id, filter: vm.globalStatsSelectedFilters.toLowerCase() });
                vm.getAssignContacts(vm.params);

                document.body.classList.add('modal-open');
            } else {
                document.body.classList.remove('modal-open');
            }
        },

        globalStatsSelectedFilters (filter) {
            const vm = this;

            vm.getPlanAnalytics({ plan: vm.plan.id, filter: filter.toLowerCase() });
        },

        'params.per_page' () {
            const vm = this;

            vm.params.page = 1;
            vm.getAssignContacts(vm.params);
        },

        'params.filter' (filter) {
            const vm = this;

            vm.params.status = filter.toLowerCase();
            vm.getAssignContacts(vm.params);
        },

        'params.search' () {
                const vm = this;

            setTimeout(() => {
                vm.isTyping = false;
            }, 1500);
        },

        isTyping (val) {
            const vm = this;

            if (!val) {
                if (vm.params.search != null) {
                    if (vm.params.search.length >= 2 || vm.params.search.length === 0) {
                        vm.params.page = 1;
                        vm.getAssignContacts(vm.params);
                    }
                }
            }
        },
    },

    computed: mapState({
        planAnalytics: state => state.workoutModule.planAnalytics,
        planAnalyticsLoader: state => state.workoutModule.planAnalyticsLoader,
        contacts: state => state.workoutModule.activeContacts,
    }),

    methods: {
        ...mapActions({
            getPlanAnalytics: 'workoutModule/getPlanAnalytics',
            getAssignContacts: 'workoutModule/getAssignContacts',
        }),

        closeModal () {
            const vm = this;

            vm.$emit('update:modelValue', false);
        },

        togglePerPageFilter () {
            const vm = this;
            vm.actionList = false;
            vm.bulkActionDropdown = false;
            const filter = vm.$refs['workout-user-per-page-filter'];

            if (filter) {
                filter.dropdown = !filter.dropdown;
            }
        },

        closePerPageDropdown () {
            const vm = this;

            const filter = vm.$refs['workout-user-per-page-filter'];

            if (filter) {
                filter.dropdown = false;
            }
        },

        closeLeaderboardFilterDropdown () {
            const vm = this;
            vm.leaderboardFilterDropdown = false;
        },

        closeFilterDropdown () {
            const vm = this;
            vm.filterDropdown = false;
        },

        toggleContactActivity (contact) {
            const vm = this;

            vm.selectedContact  = contact;
            vm.viewActivity     = true;
        },

        handlePagination () {
            const vm = this;

            vm.getAssignContacts(vm.params);
        },
    }
}
</script>

<style scoped>
.preview_area {
    background: #f5f5f5;
}

.analytics_container {
    max-width: 1170px;
    padding: 0 20px;
    margin: 0 auto;
}

.preview_area .close_btn {
    right: 15px;
    top: 15px;
    left: auto;
    position: fixed;
    background: #eaeaea;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
}

.plan_title {
    font-size: 22px;
    line-height: 30px;
    color: #121525;
    font-weight: 500;
    text-align: left;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.plan_title ul {
    display: flex;
    align-items: center;
    padding: 10px 0 4px;
    gap: 10px;
}

.plan_title ul li {
    font-size: 15px;
    line-height: 20px;
    color: #121525;
    font-weight: 500;
    padding-right: 10px;
}

.plan_title ul li:not(:last-child) {
    border-right: 1px solid #d9d9d9;
}

.plan_title ul li span {
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    font-weight: 400;
}

.section_title {
    font-size: 20px;
    line-height: 26px;
    color: #121525;
    font-weight: 400;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 12px;
}

.section_title .filter {
    padding: 3px 0;
    margin-left: auto;
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    color: #5a5a5a;
    position: relative;
    z-index: 3;
    cursor: pointer;
}

.section_title .filter .dropdown_wpr {
    min-width: 160px;
    left: auto;
    right: 1px;
}

.section_title .filter .dropdown_wpr ul li {
    border: 0;
    text-align: left;
}

.section_title .filter i {
    margin-left: 10px;
    color: #7a7a7a;
    pointer-events: none;
}

.analytics {
    display: flex;
    margin: 20px 0 40px 0;
    gap: 30px;
}

.analytics .analytics_card:first-of-type {
    /* flex: 0 0 355px; */
    flex: 0 0 31.5%;
}

.analytics_card {
    /* border: 1px solid #eaeaea; */
    background: #fff;
    border-radius: 8px;
    width: 100%;
    padding: 20px 20px;
    box-shadow: none;
    transition: all 0.3s ease-in-out;
}

.analytics_card h4 {
    font-size: 17px;
    line-height: 25px;
    color: #5a5a5a;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
}

.analytics_card h4 .stat {
    font-size: 22px;
    line-height: 25px;
    color: #121525;
    font-weight: 500;
}

.analytics_card .analytic_info {
    display: flex;
    gap: 15px;
    margin-top: 15px;
}

.analytics_card .analytic_info.column {
    flex-direction: column;
}

.analytics_card .analytic_info li {
    flex: 1 1 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}

.analytics_card h5 {
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
}

.analytics_card h5 .stat {
    font-size: 20px;
    line-height: 25px;
    color: #121525;
    font-weight: 500;
}

.analytics_card label {
    font-size: 13px;
    line-height: 18px;
    color: #5a5a5a;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
}

.analytics_card label .workout {
    font-size: 11px;
    line-height: 14px;
    color: #2f7eed;
    font-weight: 500;
}

.analytics_card .reviews {
    margin: 5px 0 20px 0;
}

.analytics_card .reviews ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
}

.analytics_card .reviews ul li {
    flex: 1 1 auto;
    background: #f5f5f5;
    border-radius: 3px;
    padding: 10px;
    margin: 5px;
    font-size: 11px;
    line-height: 14px;
    color: #121525;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;
}

.analytics_card .ratings {
    display: flex;
    gap: 2px;
}

.analytics_card .ratings span {
    color: #2f7eed;
    font-size: 10px;
}

.analytics_card:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.analytics_card .score_circle {
    width: 40px;
    height: 40px;
    padding: 3px;
}

.score_circle .inner_circle {
    background: #fff;
}

.score_circle .inner_circle .score {
    font-size: 11px;
}

.analytics_modal .tab_row li.active {
    color: #2f7eed;
}

.analytics_modal .tab_row {
    padding: 20px 0 0 0;
    display: flex;
    gap: 30px;
}

.analytics_modal .tab_row li {
    padding-bottom: 12px;
    position: relative;
    cursor: pointer;
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    color: #121525;
    overflow: hidden;
}

.analytics_modal .tab_row li:after {
    position: absolute;
    content: '';
    left: -100%;
    bottom: 0;
    width: 100%;
    border-bottom: 1px solid #2F80ED;
    transition: all 0.3s ease-in-out;
}

.analytics_modal .tab_row li.active {
    color: #2f7eed;
}

.analytics_modal .tab_row li.active:after {
    left: 0;
}

.leaderboard_wpr {
    margin: 15px 0 40px 0;
}

.leaderboard {
    border: 0;
}

.leaderboard .board_ttl {
    justify-content: flex-start;
    gap: 10px;
    line-height: 18px;
    border: 0;
    padding: 12px 20px 12px 15px;
}

.leaderboard .board_ttl i {
    color: #2f7eed;
}

.leaderboard .board_ttl i.mic {
    color: #5a5a5a;
    cursor: pointer;
    margin-left: auto;
}

.leaderboard ul {
    background: #fff;
    height: 200px;
}

.leaderboard ul li h5 {
    margin-right: auto;
}

.leaderboard ul li .streaks {
    font-size: 13px;
    line-height: 16px;
    color: #5a5a5a;
    font-weight: 400;
}

.leaderboard ul li .ratings {
    display: flex;
    gap: 1px;
}

.leaderboard ul li .ratings span {
    color: #2f7eed;
    font-size: 8px;
}

.result_wpr table td:first-child,
.result_wpr table th:first-child {
    width: auto;
    text-align: left;
    padding-left: 15px;
}

.result_wpr table td .tag {
    padding: 3px 10px;
    font-size: 11px;
    line-height: 13px;
    font-weight: 400;
    border-radius: 10px;
    background: #efefef;
    color: #121525;
}

.result_wpr table td button {
    color: #2f7eed;
}

.modal .modal_header .close_btn.left_out {
    position: absolute;
}

.modal.workout_modal .modal_body {
    padding-top: 0;
    background: #fff;
}

.modal.workout_modal .btn_list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: -5px;
}

.modal.workout_modal .btn_list li {
    font-size: 11px;
    line-height: 15px;
    color: #121525;
    padding: 5px 10px;
    white-space: nowrap;
    cursor: pointer;
}

.week_list {
    padding: 20px 0;
}

.week_list .day_guide {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 0 0 20px 0;
    /* max-width: 350px;
    margin: 0 auto; */
}

.week_list .day_guide .guide_item {
    width: calc(50% - 10px);
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
    align-items: center;
    background: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
}

.week_list .day_guide .score_circle {
    width: 40px;
    height: 40px;
    padding: 3px;
}

.week_list .day_guide .item_stat {
    flex: 1 1 auto;
    display: flex;
    gap: 10px;
}

.week_list .day_guide .legend {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #c1c1c1;
    position: relative;
    margin-top: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.week_list .day_guide .legend.active {
    background: #93c0ff;
}

.week_list .day_guide .legend.missed {
    background: #ffb4b4;
}

.week_list .day_guide .legend i {
    color: #fff;
    font-size: 7px;
}

.week_list .day_guide label {
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    color: #121525;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.week_list .day_guide label span {
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    color: #5a5a5a;
}

.week_list ul {
    position: relative;
    padding-bottom: 30px;
}

.week_list ul:before {
    position: absolute;
    content: '';
    left: 11px;
    top: 86px;
    bottom: 101px;
    border-left: 1px solid #d9d9d9;
}

.week_list ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
    position: relative;
}

.week_list ul li:before {
    position: absolute;
    content: '';
    width: calc(10% + 1px);
    left: 23px;
    top: calc(50% - 1px);
    margin-top: 7px;
    border-bottom: 1px solid #d9d9d9;
}

.week_list ul li .bullet_icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    border-radius: 50%;
    background: #e9e9e9;
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    color: #2f7eed;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    margin-right: 10%;
    margin-top: 14px;
}

.week_list ul li .bullet_icon:before {
    box-sizing: border-box;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #eff5ff;
    left: 0;
    top: 0;
    z-index: -1;
}

.week_list .exercise_info {
    flex: 0 1 580px;
    margin-left: auto;
    padding: 15px;
    border-radius: 10px;
    background: #eff5ff;
    position: relative;
    z-index: 1;
}

.week_list ul li.completed {
    pointer-events: none;
}

.week_list ul li.completed .exercise_info {
    filter: grayscale(1);
}

.week_list .exercise_info h4 {
    font-size: 13px;
    line-height: 18px;
    color: #121525;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;
}

.week_list .exercise_info h4 span {
    margin-left: auto;
    color: #5a5a5a;
    font-size: 11px;
    font-weight: 400;
}

.week_days_list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 3px;
    margin-top: 10px;
    position: relative;
}

.week_days_list label {
    flex: 1 1 30px;
}

.week_days_list label .item_wpr {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    margin: 0;
    padding: 6px 4px;
    background: transparent;
    border: 1px solid transparent;
    border-radius: 3px;
    transition: all 0.3s ease-in-out;
}

.week_days_list label .item_wpr.present_day {
    border: 1px solid #bebebe;
}

.week_days_list label span {
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    font-weight: 400;
}

.week_days_list label .day_stat {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #c1c1c1;
    position: relative;
    margin-top: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.week_days_list label .day_stat.active {
    background: #93c0ff;
}

.week_days_list label .day_stat.active.missed {
    background: #ffb4b4;
}

.week_days_list label .day_stat i {
    color: #fff;
    font-size: 7px;
}

.workout_modal .slot_content {
    display: flex;
    flex-direction: column;
}

.workout_modal .slot_content .nav_bar {
    padding: 15px 45px;
    margin: 0 -45px;
    background: #fff;
    border-bottom: 1px solid #f5f5f5;
    position: sticky;
    top: 0;
    z-index: 2;
}

.workout_modal .slot_content .nav_bar .back_btn {
    font-size: 12px;
    font-weight: 15px;
    font-weight: 500;
    color: #5a5a5a;
    display: flex;
    align-items: center;
    gap: 7px;
    cursor: pointer;
}

.workout_modal .slot_content .toggle_content {
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}

.workout_modal .slot_content .toggle_content.show {
    max-height: 100000px;
}

.workout_modal .slot_content .empty_text {
    font-size: 13px;
    line-height: 18px;
    font-weight: 300;
    color: #999;
    text-align: center;
    padding: 25px 0;
}

.workout_modal h3.sub_heading2 {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    padding: 30px 0 0;
    margin: 0;
}

.workout_modal h3.sub_heading2 i {
    color: #999;
    margin-left: auto;
    transform: rotate(0);
    transition: all 0.3s ease-in-out;
    display: none;
}

.workout_modal h3.sub_heading2.rotate i {
    transform: rotate(-180deg);
}

.workout_modal .workout_card {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    padding-bottom: 10px;
    transition: all 0.3s ease-in-out;
    border-bottom: 1px solid #e9e9e9;
}

.workout_modal .workout_card:last-of-type {
    border: 0;
    padding-bottom: 20px;
}

.workout_modal .workout_card li {
    display: flex;
    flex-direction: column;
}

.workout_modal .workout_card li a {
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: var(--var-calendar-event-cta-color, #2f7eed);
    margin: 0 0 0 auto;
    cursor: pointer;
}

.workout_modal .workout_card li .category_title {
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    color: #121525;
    margin: 0 auto 7px 0;
}

.workout_modal .workout_card li .card_wrapper {
    border-radius: 6px;
    display: flex;
    gap: 12px;
    cursor: pointer;
    padding: 20px 0 0 0;
}

.workout_modal .workout_card li .card_wrapper svg {
    flex: 0 0 18px;
    height: 18px;
}

.workout_modal .workout_card li .video_area {
    width: 25%;
    border-right: 1px solid #f5f5f5;
}

.workout_modal .workout_card li .video_area img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.workout_modal .workout_card li .info_wpr {
    border-bottom: 1px solid #e9e9e9;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding-bottom: 20px;
    gap: 0;
    transition: all 0.3s ease-in-out;
}

.workout_modal .workout_card li:last-child .info_wpr {
    border-bottom: 0;
}

.workout_modal .workout_card li .info_wpr h4 {
    font-size: 13px;
    line-height: 18px;
    color: #121525;
    font-weight: 500;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-right: 20px;
    gap: 7px;
    justify-content: space-between;
    position: relative;
}

.workout_modal .workout_card li .info_wpr span.tag {
    background: #ecf4ff;
    padding: 1px 6px;
    border-radius: 8px;
    font-size: 9px;
    line-height: 15px;
    font-weight: 500;
    color: #2f7eed;
}

.workout_modal .workout_card li .info_wpr h4 i {
    position: absolute;
    top: 5px;
    right: 0;
    font-size: 10px;
    color: #999;
    transition: all 0.3s ease-in-out;
}

.workout_modal .workout_list li .info_wpr h4 i {
    margin-left: 15px;
    pointer-events: none;
}

.workout_modal .workout_card li .info_wpr h6 {
    font-size: 11px;
    line-height: 15px;
    color: #5a5a5a;
    font-weight: 500;
    display: flex;
    flex-wrap: wrap;
    /* margin: 0 -5px 5px -5px; */
    gap: 5px;
}

.workout_modal .workout_card li .info_wpr h6 span {
    padding: 0 5px 0 0;
}

.workout_modal .workout_card li .info_wpr h6 span:not(:last-child) {
    border-right: 1px solid #d9d9d9;
}

.workout_modal .workout_card li .info_wpr .blocks {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-bottom: 10px;
    flex: 0 0 100%;
}

.workout_modal .workout_card li .info_wpr .blocks .block_title {
    font-size: 13px;
    line-height: 18px;
    color: #121525;
    font-weight: 400;
}

.workout_modal .workout_card li .info_wpr .blocks .block_title .block_type {
    font-size: 9px;
    line-height: 12px;
    color: var(--var-calendar-event-tag-text-color, #2f7eed);
    font-weight: 500;
    margin-left: 10px;
    background: var(--var-calendar-event-tag-color, #ecf4ff);
    padding: 3px 8px;
    border-radius: 10px;
}

.workout_modal .workout_card li .info_wpr .blocks .block_title h6 {
    font-weight: 400;
    margin-top: 10px;
}

.workout_modal .workout_card li .info_wpr .block_item {
    border: 1px solid #f5f5f5;
    background: #fff;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}

.workout_modal .workout_card li .info_wpr .block_item h5 {
    font-size: 11px;
    line-height: 16px;
    color: #121525;
    font-weight: 500;
}

.workout_modal .workout_card li .info_wpr .block_item h6 {
    font-size: 9px;
    line-height: 12px;
    color: #5a5a5a;
    font-weight: 400;
}

.workout_modal .workout_card li .info_wpr .block_item h6 .v_tag {
    width: 14px;
    height: 14px;
    background: #999;
    border: 1px solid #e9e9e9;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    line-height: 10px;
    font-weight: 500;
    color: #fff;
    margin-right: 5px;
    border-radius: 50%;
    padding: 0;
}

.table_wpr {
    width: 406px;
    overflow-x: auto;
}

table.task_history {
    min-width: 350px;
    width: 100%;
    border-spacing: 1px;
    background: #eaeaea;
}

.task_history th,
.task_history td {
    padding: 10px 0;
    border-radius: 3px;
    text-align: center;
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    color: #121525;
}

.task_history thead tr {
    border-bottom: 1px solid #e9e9e9;
}

.task_history td.exercise_title {
    padding: 7px 15px;
    background: #fbfbfb;
    font-size: 11px;
    line-height: 16px;
    color: #121525;
    font-weight: 500;
    text-align: left;
    cursor: pointer;
}

.task_history td.exercise_title .title {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
}

.task_history td.exercise_title .title i {
    color: #5a5a5a;
}

.task_history td.exercise_title img {
    display: none;
}

.task_history td.exercise_title.show img {
    display: block;
}

.task_history td {
    width: 10%;
    background: #fff;
    color: #777;
}

.workout_modal .workout_card li .info_wpr .details {
    display: flex;
    flex-wrap: wrap;
    max-height: 0;
    overflow: hidden;
    gap: 15px;
    transition: all 0.3s ease-in-out;
}

.workout_modal .workout_card li .info_wpr .details .feedback_btn {
    background: #f5f5f5;
    padding: 4px 10px;
    border-radius: 11px;
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    color: #121525;
    margin: 10px auto 0 auto;
}

.workout_modal .workout_card li .info_wpr .details .workout_reviews {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-height: 0;
    padding-bottom: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}

.workout_modal .workout_card li .info_wpr .details .workout_reviews.show {
    max-height: 500px;
    padding-bottom: 15px;
    margin-top: 10px;
}

.workout_reviews .finish_timer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 0 15px;
}

.workout_reviews h3 {
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    color: #121525;
}

.workout_reviews .finish_timer .total_timer {
    display: flex;
    gap: 7px;
}

.workout_reviews .finish_timer .total_timer label {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    line-height: 25px;
    color: #121525;
    font-weight: 500;
}

.workout_reviews .finish_timer .total_timer label span {
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #5a5a5a;
}

.workout_reviews .reviews {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.workout_reviews .reviews ul {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
    width: 100%;
}

.workout_reviews .reviews ul li {
    flex: 1 1 auto;
    background: #f5f5f5;
    border-radius: 3px;
    padding: 10px;
    font-size: 9px;
    line-height: 12px;
    color: #121525;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.workout_reviews .reviews ul li span {
    font-size: 15px;
    line-height: 18px;
    color: #121525;
    font-weight: 500;
}

.workout_reviews .notes {
    background: #f5f5f5;
    padding: 10px 15px 15px 15px;
    border-radius: 5px;
}

.workout_reviews .notes h4 {
    font-size: 13px;
    line-height: 16px;
    color: #121525;
    font-weight: 500;
    margin-bottom: 5px;
}

.workout_reviews .notes p {
    font-size: 11px;
    line-height: 16px;
    color: #5a5a5a;
    font-weight: 400;
}

.workout_modal .workout_card li .info_wpr .details .footer_action {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.cloud-icon {
    background: #f0f6ff;
    padding: 1px 6px;
    border-radius: 8px;
    font-size: 9px;
    line-height: 15px;
    font-weight: 500;
    color: #2f7eed;
}

.cloud-icon.green {
    background: #effff2;
    color: #23993e;
}

.workout_modal .workout_card li .info_wpr .details p.already-submitted {
    font-size: 11px;
    line-height: 14px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: auto;
}

.workout_modal .workout_card li .info_wpr .details .redirect_btn {
    font-size: 11px;
    line-height: 14px;
    padding: 6px 12px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    margin: 0 0 0 auto;
    text-decoration: none;
}

.workout_modal .workout_card li .info_wpr .details .details_info {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    gap: 7px;
}

.workout_modal .workout_card li.show .info_wpr {
    gap: 15px;
}

.workout_modal .workout_card li.show .info_wpr h4 i {
    transform: rotate(-180deg);
}

.workout_modal .workout_card li.show .info_wpr .details {
    max-height: 10000px;
}

.workout_modal .view_plan {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    height: 100vh;
    background: #fff;
    z-index: 3;
    transition: all 0.5s ease-in-out;
    overflow-y: scroll;
}

.workout_modal .view_plan::-webkit-scrollbar {
    display: none;
}

.workout_modal .view_plan.show {
    top: 0;
}

.workout_modal .view_plan .close_btn {
    position: absolute;
    left: 50%;
    right: auto;
    top: 0;
    transform: translateX(-50%);
    padding: 0 15px;
    background: #2f7fed;
    border-radius: 0 0 6px 6px;
    font-size: 10px;
    color: #fff;
    cursor: pointer;
    width: auto;
    height: 12px;
}

.workout_modal .view_plan .header {
    border-bottom: 1px solid #e9e9e9;
    background: #fff;
    z-index: 11;
    position: sticky;
    top: 0;
    padding: 30px 0 15px 0;
}

.workout_modal .view_plan h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    line-height: 25px;
    font-weight: 500;
    color: #121525;
}

.workout_modal .cell {
    width: 405px;
    padding: 60px 15px 80px;
    border-radius: 40px;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
    position: relative;
    margin: 30px auto;
}

.workout_modal .cell:after,
.workout_modal .cell:before {
    position: absolute;
    content: "";
    width: 50px;
    background: #f2f2f2;
    left: 50%;
    transform: translateX(-50%);
}

.workout_modal .cell:before {
    height: 7px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    top: 25px;
}

.workout_modal .cell:after {
    height: 50px;
    border: 1px solid #d9d9d9;
    border-radius: 50%;
    bottom: 15px;
}

.workout_modal .cell .content_area {
    height: 610px;
    padding: 0;
    border: 1px solid #e7e7e7;
    background: #fafafb;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.workout_modal .cell .content_area::-webkit-scrollbar {
    display: none;
}

.workout_modal .stats {
    margin-top: 30px;
}

.workout_modal .stats ul {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
}

.workout_modal .stats ul li {
    flex: 1 1 auto;
    background: #f5f5f5;
    border-radius: 3px;
    padding: 10px;
    font-size: 11px;
    line-height: 14px;
    color: #121525;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;
}

.workout_modal .stats ul li h4 {
    font-size: 15px;
    line-height: 18px;
    color: #121525;
    font-weight: 500;
}

.workout_modal .stats ul li .ratings {
    display: flex;
    gap: 4px;
}

.workout_modal .stats ul li .ratings span {
    color: #2f7eed;
    font-size: 10px;
}

.notes_list .search_area {
    background: #f9f9f9;
    border-radius: 20px;
    padding-right: 25px;
    position: relative;
    margin-bottom: 20px;
}

.notes_list .search_area input {
    height: 32px;
    width: 100%;
    background: transparent;
    padding: 0 15px;
    font-size: 11px;
    font-weight: 400;
    color: #5a5a5a;
}

.notes_list .search_area .search_btn {
    position: absolute;
    right: 3px;
    top: 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    font-size: 11px;
    color: #5a5a5a;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.notes_list ul {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-top: 10px;
}

.notes_list ul li {
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.notes_list ul li label {
    font-size: 11px;
    line-height: 14px;
    color: #121525;
    font-weight: 500;
}

.notes_list ul li .note {
    background: #f5f5f5;
    border-radius: 5px;
    padding: 15px;
    line-height: 0;
}

.notes_list ul li .note .user {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-bottom: 15px;
}

.notes_list ul li .note .user img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.notes_list ul li .note .user_info {
    font-size: 13px;
    line-height: 16px;
    color: #121525;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.notes_list ul li .note .user_info span {
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    font-weight: 400;
}

.notes_list ul li .note q {
    font-size: 13px;
    line-height: 20px;
    color: #5a5a5a;
    font-weight: 400;
    font-style: italic;
}

.notes_list ul li .note q:before,
.notes_list ul li .note q:after {
    font-size: 15px;
}

.notes_list ul li .note textarea {
    width: 100%;
    max-height: 0;
    height: 100px;
    overflow: hidden;
    padding: 0 15px;
    border-radius: 5px;
    background: #fff;
    font-size: 13px;
    line-height: 20px;
    color: #5a5a5a;
    font-weight: 400;
    resize: none;
    margin: 0;
    transition: all 0.3s ease-in-out;
}

.notes_list ul li .action_area {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}

.notes_list ul li .timeline {
    font-size: 11px;
    line-height: 14px;
    color: #999;
    font-weight: 400;
    font-style: italic;
    margin-right: auto;
}

.notes_list ul li .cancel_btn {
    font-size: 11px;
    line-height: 14px;
    color: #121525;
    font-weight: 500;
    cursor: pointer;
    padding-right: 10px;
    border-right: 1px solid #d9d9d9;
    display: none;
}

.notes_list ul li .reply_btn {
    font-size: 11px;
    line-height: 14px;
    color: #2f7eed;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.notes_list ul li .reply_btn.send {
    display: none;
}

.notes_list ul li.active .note textarea {
    max-height: 100px;
    padding: 10px 15px;
    margin: 10px 0 0 0;
}

.notes_list ul li.active .reply_btn:not(.send) {
    display: none;
}

.notes_list ul li.active .reply_btn.send,
.notes_list ul li.active .cancel_btn {
    display: block;
}

:deep(.quote_wpr) {
    height: 100%;
    justify-content: center;
}

.no-stats {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.result_wpr table{
    min-width: 700px;
}
@media(max-width: 767px) {
    .modal.analytics_modal .result_wpr .actions>ul>li.optionDrops {
        flex: 0 1 auto !important;
    }

    .analytics .reviews li {
        width: 33.333%;
    }

    .workout_modal .view_plan .cell {
        transform: scale(0.7) translateX(-9%) translateY(-20%);
    }
}
@media(max-width: 650px) {
    .analytics{
        flex-wrap: wrap;
    }
    
    .analytics .analytics_card, .analytics .analytics_card:first-of-type{
        flex: 0 0 100%;
    }
    .leaderboard {
        flex: 0 0 100%;
    }
}

@media(max-width: 599px) {
    .modal.analytics_modal .result_wpr .actions>ul>li.sort_list {
        margin-left: auto;
    }

    .result_wpr.new .actions>ul li.search_area {
        width: 100%;
        order: 1;
    }

    .result_wpr.new .actions>ul li.search_area input,
    .result_wpr.new .actions>ul li.search_area.active input {
        padding-left: 7px;
    }
}

@media(max-width: 499px) {
    .modal.analytics_modal .modal_container .modal_header {
        padding: 20px 20px 0 20px;
    }

    .modal.analytics_modal .modal_container .modal_body {
        padding: 0 20px;
    }

    .analytics li .analytics_card {
        padding: 15px 5px;
    }

    .analytics_card .score_circle {
        transform: none;
    }

    .analytics li .analytics_card h4 {
        margin-top: 7px !important;
    }
}
</style>